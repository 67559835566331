import request from '@/utils/request.js'

// Home data
export const HomeReq = params => {
  return request({
    method: 'GET',
    url: '/customerapi/V1/web/' + params,
  })
}

//google reviews Req
export const GoogleReviewsReq = params => {
  return request({
    method: 'GET',
    url: '/customerapi/V1/google/reviews',
    params,
  })
}

//voucher order Req
export const VoucherReq = data => {
  return request({
    method: 'POST',
    url: '/customerapi/V1/voucher/order',
    data,
  })
}

// Create a voucher prview
export const CreateVoucherPreviewReq = data => {
  return request({
    method: 'POST',
    url: '/customerapi/V1/voucher/preview',
    data,
  })
}


// List preset vouchers
export const GetPresetVouchersReq = params => {
  return request({
    method: 'GET',
    url: '/customerapi/V1/presetvouchers',
    params,
  })
}


